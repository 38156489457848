<template>
  <div class='teacher'>
    <list-template
        :loading="loading"
        :total='total'
        :table-data='tableData'
        :table-config='tableConfig'
        :current-page='page'
        @onSearch='onSearch'
        @onReset='search = null'
        @onChangePage='changeCurrentPage'
        @onHandle='tableHandle'
        hasAdd
        @onAdd='onAdd'
    ></list-template>
    <!--发布面板-->
    <el-dialog title='发布平台' :visible.sync='dialogTableVisible'>
      <el-radio v-model='radio' label='HHAO'>老平板</el-radio>
      <el-radio v-model='radio' label='HHA32'>新平板</el-radio>
      <span slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='onIssue'>发布</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '产品名称'
        },
        {
          prop: 'code',
          label: '版本号'
        },
        {
          prop: 'edition',
          label: '内部版本号'
        },
        {
          prop: 'release_at',
          label: '发布时间'
        },
        {
          label: '强制更新',
          render: (row) => {
            if (row.force === 1) return '是'
            else return '否'
          }
        },
        {
          label: '热更新',
          render: (row) => {
            if (row.type === 1) return '是'
            else return '否'
          }
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          render: (row) =>{
            if(row.type !== 1)
              return  ['发布', '编辑']
            else return ['编辑']
          }
        }
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
      dialogTableVisible: false, // 发布面板控制
      radio: 'HHAO',
      id: null,
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this;
      this.loading = true;
      this.$_axios2.get('api/evaluation/version', { params: { ...search, page } }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push('./add')
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row, handle, index) {
      this.id = row.id
      if (handle.type !== "released") {
        this.$router.push({ path: './edit', query: { id: row.id } })
      } else {
        this.dialogTableVisible = !this.dialogTableVisible
      }
    },
    // 发布按钮
    onIssue() {
      this.$_axios2.post('api/evaluation/version/publish', { id: this.id, comCode: this.radio }).then(res => {
        if (res.data.status === 0) {
          this.$message.success(res.data.msg)
        }
        this.dialogTableVisible = !this.dialogTableVisible
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
